import Popper from '../Utils/Popper'
import React, { ReactNode } from 'react'
import { SipPhoneContext } from '../SipProvider/types'
import SipProvider from '../SipProvider'

interface PhoneProps {
  showPhonePopper: boolean
  setShowPhonePopper: (value: ((prevState: boolean) => boolean) | boolean) => void
  icon: ReactNode | string
}

export default function Phone({ showPhonePopper, setShowPhonePopper, icon }: PhoneProps, context: SipPhoneContext) {
  return (
    <Popper
      value={showPhonePopper}
      onChange={setShowPhonePopper}
      className="version"
      offset={[0, 0]}
      placement="bottom-end"
      activator={<a onClick={() => setShowPhonePopper(!showPhonePopper)}>{icon}</a>}
    >
      <div className="dropdown dropdown-left">
        Status SIP: {context.sip.status ? context.sip.status : 'Not connected'}
        <br />
        Status CALL: {context.call.status ? context.call.status : 'No call'}
        <br />
        Direction: {context.call.direction}
        <br />
        Counterpart: {context.call.counterpart}
        <br />
      </div>
    </Popper>
  )
}

Phone.contextTypes = SipProvider.childContextTypes
