import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ApiUserModel,
  AuthenticationState,
  LoginRequest,
  State,
  StateUnderscore,
  User,
  UserWithUnderscoreStates,
  Worker
} from './auth.model'
import { getCommonData, getUserToken, getWorkerStatus, logout } from './auth.service'
import { RootState } from '../../store/main'
import { handleResponseError } from '../../utils/api'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { PURGE } from 'redux-persist'

let timerId: ReturnType<typeof setTimeout> | undefined

function clearLocalStorage() {
  if (timerId) clearTimeout(timerId)
}

function clearState(state: AuthenticationState, action: PayloadAction<string>) {
  state.error = action.payload
  state.isAuthenticated = false
  state.account = undefined
  state.token = undefined
  state.tokenExpireTime = undefined
  state.loginStatus = 'idle'
  state.selectedCountry = null
  clearLocalStorage()
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  error: '',
  account: undefined,
  token: undefined,
  tokenExpireTime: undefined,
  loginStatus: 'idle',
  selectedCountry: null
}

interface LoginApiResponse {
  account: UserWithUnderscoreStates
  token: string
  tokenExpireTime: DateTime
}

export const loginApi = createAsyncThunk('auth/login', async (credentials: LoginRequest, thunkAPI) => {
  try {
    return await getUserToken(credentials.username, credentials.password).then(async (tokenResponse) => {
      const commonResponse = await getCommonData(tokenResponse.data.token)
      return {
        account: commonResponse.data as UserWithUnderscoreStates,
        token: tokenResponse.data.token,
        tokenExpireTime: DateTime.now().plus({ hours: 3 })
      } as LoginApiResponse
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(handleResponseError(e))
  }
})

export const workerStatusApi = createAsyncThunk('auth/worker-status', async (_: void, thunkAPI) => {
  try {
    return await getWorkerStatus().then(async (workerResponse) => {
      return workerResponse.data.workers
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(handleResponseError(e))
  }
})

export const logoutApi = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    return await logout()
  } catch (e) {
    return thunkAPI.rejectWithValue(handleResponseError(e))
  }
})

const setAuthorize = (state: AuthenticationState, data: LoginApiResponse) => {
  const accountData = _.cloneDeep({ ...data.account, states: [] }) as User
  accountData.states = data.account.states.map((state: StateUnderscore) => {
    return {
      ...state,
      currencyCode: state.currency_code,
      currencyCodePrices: state.currency_code_prices,
      currencyInt: state.currency_int,
      fmStateId: state.fm_state_id,
      nameCz: state.name_cz,
      stateCode: state.state_code,
      vatName: state.vat_name,
      regName: state.reg_name,
      phoneValidation: state.phone_validation,
      zipValidation: state.zip_validation,
      phoneExtension: state.phone_extension
    }
  }) as State[]
  state.isAuthenticated = true
  state.token = data.token
  state.tokenExpireTime = data.tokenExpireTime
  state.account = accountData
  if (accountData.apiUserModel.selectedStateId && accountData.apiUserModel.selectedStateId.length > 0) {
    const selectedState = accountData.states.find(
      (state) => state.id.toString() === accountData.apiUserModel.selectedStateId
    )
    if (selectedState) {
      state.selectedCountry = {
        label: selectedState.stateCode,
        value: accountData.apiUserModel.selectedStateId.toString()
      }
    }
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCountry: (state, action) => {
      state.selectedCountry = action.payload
    },
    setApiUserModel: (state, action: PayloadAction<ApiUserModel>) => {
      if (state.account) {
        state.account.apiUserModel = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginApi.fulfilled.type, (state, action: PayloadAction<LoginApiResponse>) => {
      setAuthorize(state, action.payload)
      state.loginStatus = 'success'
      state.error = ''
    })
    builder.addCase(loginApi.pending.type, (state, action: PayloadAction<string>) => {
      state.loginStatus = 'pending'
    })
    builder.addCase(loginApi.rejected.type, (state, action: PayloadAction<string>) => {
      clearState(state, action)
      state.loginStatus = 'rejected'
    })
    builder.addCase(workerStatusApi.fulfilled.type, (state, action: PayloadAction<Worker[]>) => {
      if (state.account) {
        state.account.workers = action.payload
      }
    })
    builder.addCase(logoutApi.fulfilled.type, (state, action: PayloadAction<string>) => {
      clearState(state, action)
      state.error = ''
    })
    builder.addCase(logoutApi.rejected.type, (state, action: PayloadAction<string>) => {
      clearState(state, action)
    })
    builder.addCase(PURGE, (state) => {
      state.isAuthenticated = false
      state.account = undefined
      state.token = undefined
      state.tokenExpireTime = undefined
      state.loginStatus = 'idle'
      state.selectedCountry = null
    })
  }
})
export default authSlice.reducer
export const { setCountry, setApiUserModel } = authSlice.actions
export const authSelector = (state: RootState) => state.auth
