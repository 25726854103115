import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../features/auth'
import InputText from '../Form/InputText'
import Icon from '../Icon'
import Button from '../Form/Button'
import InputCheckbox from '../Form/InputCheckbox'
import clsx from 'clsx'
import { UserSettings } from '../../features/auth/auth.model'

type Props = {
  logoutCurrentUser: () => void
}

export default function NavbarContentUserDropdown({ logoutCurrentUser }: Props) {
  const router = useRouter()
  const { userAccount, saveUserSettings, selectedCountry } = useAuth()
  const [editMode, setEditMode] = React.useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultsValues = {
    invoiceAddress: userAccount?.invoiceAddress,
    invoiceCity: userAccount?.invoiceCity,
    invoicePsc: userAccount?.invoicePsc,
    personalEmail: userAccount?.personalEmail,
    personalPhone: userAccount?.personalPhone,
    bankAccount: userAccount?.bankAccount,
    receiveCopySystemEmails: userAccount?.receiveCopySystemEmails,
    callScreenTest: userAccount?.callScreenTest,
    callScreenTestPhone: userAccount?.callScreenTestPhone,
    callScreenTestEmail: userAccount?.callScreenTestEmail,
    callScreenAutoCall: userAccount?.callScreenAutoCall
  }
  const formUserSettings = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultsValues
  })
  const callScreenTest = formUserSettings.watch('callScreenTest')

  const toggleEditMode = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.preventDefault()
    setEditMode(!editMode)
  }

  const formSubmit = (data: typeof defaultsValues) => {
    setSubmitLoading(true)
    saveUserSettings({
      ...data,
      callScreenTest: !!data.callScreenTest,
      receiveCopySystemEmails: !!data.receiveCopySystemEmails,
      selectedStateId: selectedCountry ? selectedCountry.value : null
    } as UserSettings)
      .then(() => {
        setEditMode(false)
      })
      .finally(() => {
        setSubmitLoading(false)
      })
  }

  return (
    <div className="dropdown-user-settings">
      <h2>
        <span>User settings</span>
        <a onClick={toggleEditMode}>
          <Icon code="pencil" size="16" className="color-potentials" />
        </a>
      </h2>
      <form onSubmit={formUserSettings.handleSubmit(formSubmit)}>
        <InputText
          name="personalPhone"
          label="Phone"
          editMode={editMode}
          control={formUserSettings.control}
          classNameInput="medium"
        />
        <InputText
          name="personalEmail"
          label="Email"
          editMode={editMode}
          control={formUserSettings.control}
          classNameInput="medium"
        />
        <InputText
          name="invoiceAddress"
          label="Address"
          editMode={editMode}
          control={formUserSettings.control}
          classNameInput="medium"
        />
        <div className="row">
          <div className="col-xs-8">
            <InputText
              name="invoiceCity"
              label="City"
              editMode={editMode}
              control={formUserSettings.control}
              classNameInput="medium"
            />
          </div>
          <div className="col-xs-4">
            <InputText
              name="invoicePsc"
              label="Zip code"
              editMode={editMode}
              control={formUserSettings.control}
              classNameInput="medium"
            />
          </div>
        </div>
        <InputText
          name="bankAccount"
          label="Bank account"
          editMode={editMode}
          control={formUserSettings.control}
          classNameInput="medium"
        />
        <InputCheckbox
          name="receiveCopySystemEmails"
          label="Recive copy system emails sent by me on my email?"
          editMode={editMode}
          control={formUserSettings.control}
        />
        <InputCheckbox
          name="callScreenAutoCall"
          label="Auto-start calling when start calling is pressed?"
          editMode={editMode}
          control={formUserSettings.control}
        />
        <InputCheckbox
          name="callScreenTest"
          label="Calling test mode?"
          editMode={editMode}
          control={formUserSettings.control}
        />
        <div className={clsx('row', callScreenTest ? 'show-flex' : 'hide')}>
          <div className="col-xs-6">
            <InputText
              name="callScreenTestPhone"
              label="Test phone"
              helpText="Including phone extension"
              editMode={editMode}
              control={formUserSettings.control}
              classNameInput="medium"
            />
          </div>
          <div className="col-xs-6">
            <InputText
              name="callScreenTestEmail"
              label="Test email"
              editMode={editMode}
              control={formUserSettings.control}
              classNameInput="medium"
            />
          </div>
        </div>
        <div className="dropdown-user-settings-buttons">
          {editMode && (
            <>
              <Button prefix="Save" type="submit" color="green" loading={submitLoading} />
              <Button
                prefix="Cancel"
                onClick={() => {
                  setEditMode(false)
                }}
                color="red-full"
              />
            </>
          )}
          {!editMode && (
            <a onClick={logoutCurrentUser} className="logoutLink">
              Log out
            </a>
          )}
        </div>
      </form>
    </div>
  )
}
