import packageJson from '../../../package.json'
import Popper from '../Utils/Popper'
import React from 'react'
import { useAuth } from '../../features/auth'

interface VersionProps {
  showVersionMenu: boolean
  setShowVersionMenu: (value: ((prevState: boolean) => boolean) | boolean) => void
}

export default function Version({ showVersionMenu, setShowVersionMenu }: VersionProps) {
  const { getSettingByProperty } = useAuth()
  const leatestVersion = getSettingByProperty('Intranet version')

  return (
    <Popper
      value={showVersionMenu}
      onChange={setShowVersionMenu}
      className="version"
      offset={[0, 0]}
      placement="bottom-start"
      activator={
        <div
          onClick={(e) => {
            e.preventDefault()
            setShowVersionMenu(!showVersionMenu)
          }}
          className={leatestVersion && leatestVersion.value !== packageJson.version ? 'new-version' : 'same-version'}
        >
          <div>
            <span>{packageJson.version}</span>
            {leatestVersion && leatestVersion.value !== packageJson.version && (
              <span className="color-orange">New version available ({leatestVersion.value})</span>
            )}
          </div>
        </div>
      }
    >
      <div className="dropdown dropdown-left">
        <a href="https://rapid-order.rendl.com/intranet-app/index.php" target="_blank">
          Download intranet
        </a>
        <br />
        <br />
        Version: {packageJson.version}
        {leatestVersion && leatestVersion.value !== packageJson.version && (
          <>
            <br />
            <br />
            <strong className="color-red">New version available ({leatestVersion.value})</strong>
          </>
        )}
      </div>
    </Popper>
  )
}
