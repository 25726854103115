import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { API_URL, API_VER } from '../config'
import { store } from '../store/main'
import { BasicFormResponse } from '../types'
import packageJson from '../../package.json'

export class ApiWrapper {
  private client: AxiosInstance
  private url: string
  private version: string
  private tokenStored: string | undefined

  constructor(url: string, version: string, token?: string) {
    const axiosBaseConfig = {
      baseURL: url,
      headers: {}
    }

    if (token) {
      axiosBaseConfig.headers = {
        Authorization: 'Bearer ' + token,
        AppVersion: packageJson.version,
        AppMode: process.env.NODE_ENV
      }
      this.tokenStored = token
    }
    this.client = axios.create(axiosBaseConfig)
    this.url = url
    this.version = version
  }

  public setToken(token: string) {
    this.tokenStored = token
    const axiosBaseConfig = {
      baseURL: this.url,
      headers: {
        Authorization: 'Bearer ' + token,
        AppVersion: packageJson.version,
        AppMode: process.env.NODE_ENV
      }
    }
    this.client = axios.create(axiosBaseConfig)
  }

  public getToken() {
    return this.tokenStored
  }

  public buildUrl(path: string, getParams: any, addVersion: boolean | undefined) {
    let url = path
    if (addVersion || addVersion === undefined) {
      url = `${this.version}/${url}`
    }
    if (getParams) {
      url += '?' + new URLSearchParams(getParams)
    }
    return url
  }

  public setTokenFromStore() {
    const state = store.getState()
    if (state.auth.token !== undefined) {
      this.setToken(state.auth.token)
    }
  }

  public get(path: string, params?: any, addVersion?: boolean) {
    this.setTokenFromStore()
    const apiUrl = this.buildUrl(path, params, addVersion)
    return this.client.get(apiUrl)
  }

  public delete(path: string, params?: any, addVersion?: boolean) {
    this.setTokenFromStore()
    const apiUrl = this.buildUrl(path, params, addVersion)
    return this.client.delete(apiUrl)
  }

  public post(path: string, params?: any, getParams?: any, addVersion?: boolean) {
    this.setTokenFromStore()
    const apiUrl = this.buildUrl(path, getParams, addVersion)
    return this.client.post(apiUrl, params)
  }

  public put(path: string, params?: any, getParams?: any, addVersion?: boolean) {
    this.setTokenFromStore()
    const apiUrl = this.buildUrl(path, getParams, addVersion)
    return this.client.put(apiUrl, params)
  }

  public handleResponseError(error: any) {
    let message = error.message
    if (error.response !== undefined && error.response.data !== undefined) {
      message = error.response.data.message
    }
    return message
  }

  public handleResponseFormData(response: AxiosResponse): BasicFormResponse {
    return response.data as BasicFormResponse
  }
}

export const api = new ApiWrapper(API_URL, API_VER)
export const handleResponseError = api.handleResponseError
