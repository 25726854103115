import * as React from 'react'
import Select, { ActionMeta, components } from 'react-select'
import { useAuth } from '../../features/auth'
import { Option, SelectOption } from '../../types'
import ReactCountryFlag from 'react-country-flag'
import Icon from '../Icon'

export function CountryFlag({ code, size }: { code: string; size?: string }) {
  const iconSize = size || '32'
  if (code === 'en') {
    code = 'gb'
  }
  if (code === 'all') {
    return (
      <Icon
        code="all-country"
        size={iconSize}
        style={{
          marginRight: '5px'
        }}
      />
    )
  }
  return (
    <ReactCountryFlag
      countryCode={code}
      svg
      style={{
        width: iconSize + 'px',
        height: '21px',
        marginRight: '5px'
      }}
    />
  )
}

const CountryFlagSelectOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="react-select-option">
        {props.label.length > 0 && <CountryFlag code={props.label.toLowerCase()} />}
        {props.label}
      </div>
    </components.Option>
  )
}

const CountryFlagValueContainer = ({ children, ...props }: any) => {
  const code = (props.hasValue && props.getValue()[0].label) || false

  return (
    <div className="react-select-option">
      {(code.length > 0 && <CountryFlag code={code.toLowerCase()} />) || null}
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </div>
  )
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon code="chevron-down" className="color-white" />
    </components.DropdownIndicator>
  )
}

interface CountrySelectorProps {
  country: SelectOption | null
  onSetCountry: (newCountry: SelectOption | null) => void
  className?: string
  setInitValue?: boolean
  isClearable?: boolean
  includeAllOption?: boolean
  minWidth?: number
  initCountryId?: number | null
  width?: string
  placeHolderColor?: string
}

export const CountrySelector = ({
  country,
  className,
  onSetCountry,
  setInitValue = true,
  isClearable = false,
  includeAllOption = true,
  minWidth,
  width,
  initCountryId,
  placeHolderColor
}: CountrySelectorProps) => {
  const { account } = useAuth()
  let userStates: Option[] = []
  if (includeAllOption) {
    userStates.push({
      value: '',
      label: 'All'
    })
  }

  if (account?.states !== undefined && account?.states !== null && Array.isArray(account?.states)) {
    userStates = account?.states.reduce((allStates, state) => {
      if (state.stateCode !== undefined && state.stateCode.length > 0) {
        allStates.push({
          value: state.id.toString(),
          label: state.stateCode
        } as Option)
      }
      return allStates
    }, userStates)
  }
  let userAllowedStateSelect = false
  if (account?.apiUserModel) {
    userAllowedStateSelect = account.apiUserModel.allowedStates?.length > 0
  }
  const countryRef = React.useRef(country)

  const memoizedStates = React.useMemo(() => {
    return account?.states.map((state) => ({
      value: state.id.toString(),
      label: state.stateCode
    }))
  }, [account?.states])

  React.useEffect(() => {
    if (setInitValue && country === null && userStates && userAllowedStateSelect) {
      onSetCountry(userStates[0])
    }
  }, [userStates, onSetCountry, country, userAllowedStateSelect, setInitValue])

  React.useEffect(() => {
    countryRef.current = country
  }, [country])

  React.useEffect(() => {
    if (countryRef.current === null && Number.isFinite(initCountryId)) {
      const foundCountry = memoizedStates?.find((state) => parseInt(state.value) === initCountryId)
      onSetCountry(foundCountry!)
    }
  }, [initCountryId, memoizedStates, onSetCountry])

  const changeHandler = (value: SelectOption, _actionMeta: ActionMeta<Option>) => {
    onSetCountry(value)
  }

  return (
    <div className={className} style={{ minWidth, width }}>
      <Select
        options={userStates}
        value={country}
        onChange={changeHandler}
        isClearable={isClearable}
        className="react-select"
        classNamePrefix="react-select"
        placeholder="Country"
        components={{
          Option: CountryFlagSelectOption,
          ValueContainer: CountryFlagValueContainer,
          DropdownIndicator
        }}
      />
    </div>
  )
}
