import React from 'react'
import Head from 'next/head'
import { MAIN_TITLE } from '../../config'
import Layout from '../../layout/base'
import Content from '../../layout/content'
import Link from 'next/link'

interface ErrorPageProps {
  error: Error
}

export default function ErrorPage({ error }: ErrorPageProps) {
  const printStackTrace = (error: Error) => {
    if (!error.stack) return ''
    return error.stack
      .split('\n')
      .slice(2)
      .map((line: string) => line.replace(/\s+at\s+/, ''))
      .join('\n')
  }

  return (
    <React.Fragment>
      <Head>
        <title>Error occured - {MAIN_TITLE}</title>
      </Head>
      <Layout title="Error">
        <Content>
          <div className="error-page">
            <div>
              <h1>Error occured</h1>
              <h2>Internal system error</h2>
              <p>{error.message}</p>
              <p>{printStackTrace(error)}</p>
              <p>
                Internal system error please try to go back to homepage. <Link href="/">Go back to homepage</Link>.
              </p>
            </div>
          </div>
        </Content>
      </Layout>
    </React.Fragment>
  )
}
