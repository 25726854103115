import Icon from '../Icon'
import Link from 'next/link'
import React, { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { logoutApi, useAuth } from '../../features/auth'
import { useOnClickOutside } from 'usehooks-ts'
import { useAppDispatch } from '../../hooks/main'
import { CountrySelector } from '../CountrySelector/CountrySelector'
import Popper from '../Utils/Popper'
import NavbarContentUserDropdown from '../NavbarContent/NavbarContentUserDropdown'
import Version from '../Poppers/Version'
import Phone from '../Poppers/Phone'
import { Option } from '../../types'

type Props = {
  title: string
}

const menuItems = [
  { title: 'Search', href: '/potentials/results' },
  { title: 'Contacts', href: '/potentials/contacts' },
  { title: 'Call campaigns', href: '/call-campaigns' },
  { title: 'Worker stats', href: '/potentials/worker-stats' }
]

export default function Navbar({ title }: Props) {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { account, isAuthenticated } = useAuth()
  const [showUserMenu, setShowUserMenu] = React.useState(false)
  const [showVersionMenu, setShowVersionMenu] = React.useState(false)
  const [showPhonePopper, setShowPhonePopper] = React.useState(false)
  const userMenuToggleRef = useRef(null)
  const { selectedCountry, setCountry, isAllowedIncomingCall } = useAuth()

  function currentPathContains(searchString: string) {
    return router.pathname.search(searchString) !== -1
  }

  function toogleShowUserMenu(e: React.MouseEvent) {
    e.preventDefault()
    setShowUserMenu(!showUserMenu)
  }

  useOnClickOutside(userMenuToggleRef, () => setShowUserMenu(false))

  function logoutCurrentUser() {
    dispatch(logoutApi())
  }

  useEffect(() => {
    if (!isAuthenticated) {
      router.push('/login')
    }
  }, [isAuthenticated])

  return (
    <div className="layout-navbar">
      <div className="layout-navbar-title">
        <div className="layout-navbar-title-text">
          <Link href="/">
            <Icon code="logo" title="Rendl intranet" />
            Rendl intranet
          </Link>
          <Version showVersionMenu={showVersionMenu} setShowVersionMenu={setShowVersionMenu} />
        </div>
        <div className="layout-navbar-title-menu">
          {menuItems.map((item, index) => (
            <Link href={item.href} className={currentPathContains(item.href) ? 'active' : ''} key={`navbar-${index}`}>
              {item.title}
            </Link>
          ))}
        </div>
        {account && (
          <div className="layout-navbar-user">
            {isAllowedIncomingCall && (
              <Phone
                showPhonePopper={showPhonePopper}
                setShowPhonePopper={setShowPhonePopper}
                icon={<Icon code="phone-call-circle" size="24" title="Incomming call" className="color-green" />}
              />
            )}
            <CountrySelector
              className="content-navbar-country-selector"
              country={selectedCountry === null ? ({ value: '2', label: 'CZ' } as Option) : selectedCountry}
              onSetCountry={setCountry}
              includeAllOption={false}
            />
            <Popper
              value={showUserMenu}
              onChange={setShowUserMenu}
              className="popper-link"
              offset={[0, 0]}
              placement="bottom-start"
              activator={
                <a
                  className="user-tag"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowUserMenu(!showUserMenu)
                  }}
                >
                  <span className="user-tag-shortcode">
                    {account.apiUserModel !== undefined &&
                    account.apiUserModel.shortCode !== null &&
                    account.apiUserModel.shortCode.length > 0
                      ? account.apiUserModel.shortCode
                      : '-'}
                  </span>
                  <Icon code="chevron-down" />
                </a>
              }
            >
              <NavbarContentUserDropdown logoutCurrentUser={logoutCurrentUser} />
            </Popper>
          </div>
        )}
      </div>
    </div>
  )
}
