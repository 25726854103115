import { ReactNode } from 'react'
import Link from 'next/link'

type Props = {
  to: any
  className?: string
  children?: ReactNode
}

export default function TextLink(props: Props) {
  return (
    <Link href={props.to} className={props.className}>
      {props.children}
    </Link>
  )
}
