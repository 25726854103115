import { useAuth } from '../auth.hook'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function AuthGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated, loginStatus, setRedirect, isExpired } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (loginStatus !== 'pending' && (!isAuthenticated || isExpired)) {
      setRedirect(router.route)
      router.push('/login')
    }
  }, [loginStatus, router, isAuthenticated, isExpired, setRedirect])
  if (loginStatus === 'pending') {
    return <h1>Loading...</h1>
  }
  if (isAuthenticated) {
    return <>{children}</>
  }
  return null
}
