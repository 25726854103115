import * as JsSIP from 'jssip'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import dummyLogger from './dummyLogger'
import {
  CALL_DIRECTION_INCOMING,
  CALL_DIRECTION_OUTGOING,
  CALL_STATUS_ACTIVE,
  CALL_STATUS_IDLE,
  CALL_STATUS_STARTING,
  CALL_STATUS_STOPPING,
  CallDirection,
  CallStatus,
  SIP_ERROR_TYPE_CONFIGURATION,
  SIP_ERROR_TYPE_CONNECTION,
  SIP_ERROR_TYPE_REGISTRATION,
  SIP_STATUS_CONNECTED,
  SIP_STATUS_CONNECTING,
  SIP_STATUS_DISCONNECTED,
  SIP_STATUS_ERROR,
  SIP_STATUS_REGISTERED,
  SipErrorType,
  SipStatus
} from './enums'
import { callPropType, ExtraHeaders, extraHeadersPropType, IceServers, iceServersPropType, sipPropType } from './types'
import { RTCSession } from 'jssip/lib/RTCSession'
import { UnRegisteredEvent } from 'jssip/lib/UA'
import { IncomingRequest, OutgoingRequest } from 'jssip/lib/SIPMessage'

export type SipProviderProps = {
  host: string
  port: number
  pathname: string
  user: string
  password: string
  autoRegister: boolean
  autoAnswer: boolean
  iceRestart: boolean
  sessionTimersExpires: number
  extraHeaders: ExtraHeaders
  iceServers: IceServers
  debug: boolean
  children: React.ReactNode
}

export default class SipProvider extends React.Component<
  SipProviderProps,
  {
    sipStatus: SipStatus
    sipErrorType: SipErrorType | null
    sipErrorMessage: string | null
    callStatus: CallStatus
    callDirection: CallDirection | null
    callCounterpart: string | null
    callIsOnHold: boolean
    callMicrophoneIsMuted: boolean
    rtcSession: RTCSession | null
  }
> {
  public static childContextTypes = {
    sip: sipPropType,
    call: callPropType,
    registerSip: PropTypes.func,
    unregisterSip: PropTypes.func,

    answerCall: PropTypes.func,
    startCall: PropTypes.func,
    stopCall: PropTypes.func
  }

  public static propTypes = {
    host: PropTypes.string,
    port: PropTypes.number,
    pathname: PropTypes.string,
    user: PropTypes.string,
    password: PropTypes.string,
    autoRegister: PropTypes.bool,
    autoAnswer: PropTypes.bool,
    iceRestart: PropTypes.bool,
    sessionTimersExpires: PropTypes.number,
    extraHeaders: extraHeadersPropType,
    iceServers: iceServersPropType,
    debug: PropTypes.bool,

    children: PropTypes.node
  }

  public static defaultProps = {
    host: null,
    port: null,
    pathname: '',
    user: null,
    password: null,
    autoRegister: true,
    autoAnswer: false,
    iceRestart: false,
    sessionTimersExpires: 120,
    extraHeaders: { register: [], invite: [], hold: [] },
    iceServers: [],
    debug: false,

    children: null
  }
  private ua: JsSIP.UA | null
  private remoteAudio: HTMLAudioElement | null | undefined
  private logger: Console | undefined

  constructor(props: SipProviderProps) {
    super(props)

    this.state = {
      sipStatus: SIP_STATUS_DISCONNECTED,
      sipErrorType: null,
      sipErrorMessage: null,

      rtcSession: null,
      // errorLog: [],
      callStatus: CALL_STATUS_IDLE,
      callDirection: null,
      callCounterpart: null,
      callIsOnHold: false,
      callMicrophoneIsMuted: false
    }

    this.ua = null
  }

  public getChildContext() {
    return {
      sip: {
        ...this.props,
        status: this.state.sipStatus,
        errorType: this.state.sipErrorType,
        errorMessage: this.state.sipErrorMessage
      },
      call: {
        id: '??',
        status: this.state.callStatus,
        direction: this.state.callDirection,
        counterpart: this.state.callCounterpart,
        isOnHold: this.state.callIsOnHold,
        hold: this.callHold,
        unhold: this.callUnhold,
        toggleHold: this.callToggleHold,
        microphoneIsMuted: this.state.callMicrophoneIsMuted,
        muteMicrophone: this.callMuteMicrophone,
        unmuteMicrophone: this.callUnmuteMicrophone,
        toggleMuteMicrophone: this.callToggleMuteMicrophone,
        sendDTMF: this.sendDTMF
      },
      registerSip: this.registerSip,
      unregisterSip: this.unregisterSip,

      answerCall: this.answerCall,
      startCall: this.startCall,
      stopCall: this.stopCall
    }
  }

  public componentDidMount() {
    if (window.document.getElementById('sip-provider-audio')) {
      throw new Error(
        `Creating two SipProviders in one application is forbidden. If that's not the case ` +
          `then check if you're using "sip-provider-audio" as id attribute for any existing ` +
          `element`
      )
    }

    this.remoteAudio = window.document.createElement('audio')
    this.remoteAudio.id = 'sip-provider-audio'
    window.document.body.appendChild(this.remoteAudio)

    this.reconfigureDebug()
    this.reinitializeJsSIP()
  }

  public componentDidUpdate(prevProps: SipProviderProps) {
    if (this.props.debug !== prevProps.debug) {
      this.reconfigureDebug()
    }
    if (
      this.props.host !== prevProps.host ||
      this.props.port !== prevProps.port ||
      this.props.pathname !== prevProps.pathname ||
      this.props.user !== prevProps.user ||
      this.props.password !== prevProps.password ||
      this.props.autoRegister !== prevProps.autoRegister
    ) {
      this.reinitializeJsSIP()
    }
  }

  public componentWillUnmount() {
    if (this.remoteAudio && this.remoteAudio.parentNode) {
      this.remoteAudio.parentNode.removeChild(this.remoteAudio)
    }
    delete this.remoteAudio
    if (this.ua) {
      this.ua.stop()
      this.ua = null
    }
  }

  public registerSip = () => {
    if (!this.ua) {
      throw new Error('No UA instance')
    }
    if (this.props.autoRegister) {
      throw new Error('Calling registerSip is not allowed when autoRegister === true')
    }
    if (this.state.sipStatus !== SIP_STATUS_CONNECTED) {
      throw new Error(
        `Calling registerSip is not allowed when sip status is ${this.state.sipStatus} (expected ${SIP_STATUS_CONNECTED})`
      )
    }
    return this.ua.register()
  }

  public unregisterSip = () => {
    if (!this.ua) {
      throw new Error('No UA instance')
    }
    if (this.props.autoRegister) {
      throw new Error('Calling registerSip is not allowed when autoRegister === true')
    }
    if (this.state.sipStatus !== SIP_STATUS_REGISTERED) {
      throw new Error(
        `Calling unregisterSip is not allowed when sip status is ${this.state.sipStatus} (expected ${SIP_STATUS_CONNECTED})`
      )
    }
    return this.ua.unregister()
  }

  public answerCall = () => {
    if (!this.state.rtcSession) {
      return
    }

    if (this.state.callStatus !== CALL_STATUS_STARTING || this.state.callDirection !== CALL_DIRECTION_INCOMING) {
      throw new Error(
        `Calling answerCall() is not allowed when call status is ${this.state.callStatus} and call direction is ${this.state.callDirection}  (expected ${CALL_STATUS_STARTING} and ${CALL_DIRECTION_INCOMING})`
      )
    }

    this.state.rtcSession.answer({
      mediaConstraints: {
        audio: true,
        video: false
      },
      pcConfig: {
        iceServers: this.props.iceServers
      }
    })
  }

  public startCall = (destination: string): RTCSession => {
    if (!this.ua) {
      throw new Error('No UA instance')
    }
    if (!destination) {
      throw new Error(`Destination must be defined (${destination} given)`)
    }
    if (this.state.sipStatus !== SIP_STATUS_CONNECTED && this.state.sipStatus !== SIP_STATUS_REGISTERED) {
      throw new Error(
        `Calling startCall() is not allowed when sip status is ${this.state.sipStatus} (expected ${SIP_STATUS_CONNECTED} or ${SIP_STATUS_REGISTERED})`
      )
    }

    if (this.state.callStatus !== CALL_STATUS_IDLE) {
      throw new Error(
        `Calling startCall() is not allowed when call status is ${this.state.callStatus} (expected ${CALL_STATUS_IDLE})`
      )
    }

    const { iceServers, sessionTimersExpires } = this.props
    const extraHeaders = this.props.extraHeaders.invite

    const options = {
      extraHeaders,
      mediaConstraints: { audio: true, video: false },
      rtcOfferConstraints: { iceRestart: this.props.iceRestart },
      pcConfig: {
        iceServers
      },
      sessionTimersExpires
    }

    const rtcSession = this.ua.call(destination, options)
    this.setState({ callStatus: CALL_STATUS_STARTING })
    return rtcSession
  }

  public stopCall = () => {
    if (!this.ua) {
      throw new Error('No UA instance')
    }
    this.setState({ callStatus: CALL_STATUS_STOPPING })
    this.ua.terminateSessions()
  }

  public reconfigureDebug() {
    const { debug } = this.props

    if (debug) {
      JsSIP.debug.enable('JsSIP:*')
      this.logger = console
    } else {
      JsSIP.debug.disable()
      this.logger = dummyLogger as Console
    }
  }

  public logDebug(...args: any[]) {
    if (this.logger) {
      this.logger.debug(...args)
    }
  }

  public reinitializeJsSIP() {
    if (this.ua) {
      this.ua.stop()
      this.ua = null
    }

    const { host, port, pathname, user, password, autoRegister } = this.props

    if (!host || !port || !user) {
      this.setState({
        sipStatus: SIP_STATUS_DISCONNECTED,
        sipErrorType: null,
        sipErrorMessage: null
      })
      return
    }

    try {
      const socket = new JsSIP.WebSocketInterface(`wss://${host}:${port}${pathname}`)
      this.ua = new JsSIP.UA({
        uri: `sip:${user}@${host}`,
        password,
        sockets: [socket],
        register: autoRegister
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.logDebug('Error', error.message, error)
        this.setState({
          sipStatus: SIP_STATUS_ERROR,
          sipErrorType: SIP_ERROR_TYPE_CONFIGURATION,
          sipErrorMessage: error.message
        })
      } else {
        this.logDebug('Error', error)
        this.setState({
          sipStatus: SIP_STATUS_ERROR,
          sipErrorType: SIP_ERROR_TYPE_CONFIGURATION,
          sipErrorMessage: 'Unknown error'
        })
      }
      return
    }

    const { ua } = this
    ua.on('connecting', () => {
      this.logDebug('UA "connecting" event')
      if (this.ua !== ua) {
        return
      }
      this.setState({
        sipStatus: SIP_STATUS_CONNECTING,
        sipErrorType: null,
        sipErrorMessage: null
      })
    })

    ua.on('connected', () => {
      this.logDebug('UA "connected" event')
      if (this.ua !== ua) {
        return
      }
      this.setState({
        sipStatus: SIP_STATUS_CONNECTED,
        sipErrorType: null,
        sipErrorMessage: null
      })
    })

    ua.on('disconnected', () => {
      this.logDebug('UA "disconnected" event')
      if (this.ua !== ua) {
        return
      }
      this.setState({
        sipStatus: SIP_STATUS_ERROR,
        sipErrorType: SIP_ERROR_TYPE_CONNECTION,
        sipErrorMessage: 'disconnected'
      })
    })

    ua.on('registered', (data) => {
      this.logDebug('UA "registered" event', data)
      if (this.ua !== ua) {
        return
      }
      this.setState({
        sipStatus: SIP_STATUS_REGISTERED,
        callStatus: CALL_STATUS_IDLE
      })
    })

    ua.on('unregistered', () => {
      this.logDebug('UA "unregistered" event')
      if (this.ua !== ua) {
        return
      }
      if (ua.isConnected()) {
        this.setState({
          sipStatus: SIP_STATUS_CONNECTED,
          callStatus: CALL_STATUS_IDLE,
          callDirection: null
        })
      } else {
        this.setState({
          sipStatus: SIP_STATUS_DISCONNECTED,
          callStatus: CALL_STATUS_IDLE,
          callDirection: null
        })
      }
    })

    ua.on('registrationFailed', (data: UnRegisteredEvent) => {
      this.logDebug('UA "registrationFailed" event')
      if (this.ua !== ua) {
        return
      }
      this.setState({
        sipStatus: SIP_STATUS_ERROR,
        sipErrorType: SIP_ERROR_TYPE_REGISTRATION,
        sipErrorMessage:
          (data.response && data.response.reason_phrase && data.response.reason_phrase.length > 0
            ? data.response.reason_phrase + ', '
            : '') + data.response.status_code
      })
    })

    ua.on(
      'newRTCSession',
      ({
        originator,
        session: rtcSession,
        request: rtcRequest
      }: {
        originator: string
        session: RTCSession
        request: OutgoingRequest | IncomingRequest
      }) => {
        if (!this || this.ua !== ua) {
          return
        }

        // identify call direction
        if (originator === 'local') {
          const foundUri = rtcRequest.to.toString()
          const delimiterPosition = foundUri.indexOf(';') || null
          this.setState({
            callDirection: CALL_DIRECTION_OUTGOING,
            callStatus: CALL_STATUS_STARTING,
            callCounterpart: foundUri.substring(0, delimiterPosition || 0) || foundUri,
            callIsOnHold: rtcSession.isOnHold().local,
            callMicrophoneIsMuted: rtcSession.isMuted().audio
          })
        } else if (originator === 'remote') {
          const foundUri = rtcRequest.from.toString()
          const delimiterPosition = foundUri.indexOf(';') || null
          this.setState({
            callDirection: CALL_DIRECTION_INCOMING,
            callStatus: CALL_STATUS_STARTING,
            callCounterpart: foundUri.substring(0, delimiterPosition || 0) || foundUri,
            callIsOnHold: rtcSession.isOnHold().local,
            callMicrophoneIsMuted: rtcSession.isMuted().audio
          })
        }

        const { rtcSession: rtcSessionInState } = this.state

        // Avoid if busy or other incoming
        if (rtcSessionInState) {
          this.logDebug('incoming call replied with 486 "Busy Here"')
          rtcSession.terminate({
            status_code: 486,
            reason_phrase: 'Busy Here'
          })
          return
        }

        this.setState({ rtcSession })
        rtcSession.on('failed', () => {
          if (this.ua !== ua) {
            return
          }

          this.setState({
            rtcSession: null,
            callStatus: CALL_STATUS_IDLE,
            callDirection: null,
            callCounterpart: null,
            callMicrophoneIsMuted: false,
            callIsOnHold: false
          })
        })

        rtcSession.on('ended', () => {
          if (this.ua !== ua) {
            return
          }

          this.setState({
            rtcSession: null,
            callStatus: CALL_STATUS_IDLE,
            callDirection: null,
            callCounterpart: null,
            callMicrophoneIsMuted: false,
            callIsOnHold: false
          })
        })

        rtcSession.on('accepted', () => {
          if (this.ua !== ua) {
            return
          }
          if (!this.remoteAudio) {
            return
          }

          ;[this.remoteAudio.srcObject] = rtcSession.connection.getRemoteStreams()
          // const played = this.remoteAudio.play();
          const played = this.remoteAudio.play()

          if (typeof played !== 'undefined') {
            played
              .catch(() => {
                /**/
              })
              .then(() => {
                setTimeout(() => {
                  if (this.remoteAudio) {
                    this.remoteAudio.play()
                  }
                }, 2000)
              })
            this.setState({ callStatus: CALL_STATUS_ACTIVE })
            return
          }

          setTimeout(() => {
            if (this.remoteAudio) {
              this.remoteAudio.play()
            }
          }, 2000)

          this.setState({ callStatus: CALL_STATUS_ACTIVE })
        })

        if (this.state.callDirection === CALL_DIRECTION_INCOMING && this.props.autoAnswer) {
          this.logDebug('Answer auto ON')
          this.answerCall()
        } else if (this.state.callDirection === CALL_DIRECTION_INCOMING && !this.props.autoAnswer) {
          this.logDebug('Answer auto OFF')
        } else if (this.state.callDirection === CALL_DIRECTION_OUTGOING) {
          this.logDebug('OUTGOING call')
        }
      }
    )

    const extraHeadersRegister = this.props.extraHeaders.register || []
    if (extraHeadersRegister.length) {
      ua.registrator().setExtraHeaders(extraHeadersRegister)
    }
    ua.start()
  }

  public render() {
    return this.props.children
  }

  private callHold = (useUpdate = false) => {
    if (!this.state.rtcSession) {
      return
    }

    const holdStatus = this.state.rtcSession.isOnHold()
    if (!holdStatus.local) {
      const options = {
        useUpdate,
        extraHeaders: this.props.extraHeaders.hold
      }
      const done = () => {
        this.setState({ callIsOnHold: true })
      }
      this.state.rtcSession.hold(options, done)
    }
  }

  private callUnhold = (useUpdate = false) => {
    if (!this.state.rtcSession) {
      return
    }

    const holdStatus = this.state.rtcSession.isOnHold()
    if (holdStatus.local) {
      const options = {
        useUpdate,
        extraHeaders: this.props.extraHeaders.hold || []
      }
      const done = () => {
        this.setState({ callIsOnHold: false })
      }
      this.state.rtcSession.unhold(options, done)
    }
  }

  private callToggleHold = (useUpdate = false) => {
    let holdStatus = false
    if (this.state.rtcSession) {
      const holdStatusSession = this.state.rtcSession.isOnHold()
      if (holdStatusSession.local) {
        holdStatus = holdStatusSession.local
      }
    }
    return holdStatus ? this.callUnhold(useUpdate) : this.callHold(useUpdate)
  }

  private callMuteMicrophone = () => {
    if (this.state.rtcSession && !this.state.callMicrophoneIsMuted) {
      this.state.rtcSession.mute({ audio: true, video: false })
      this.setState({ callMicrophoneIsMuted: true })
    }
  }

  private callUnmuteMicrophone = () => {
    if (this.state.rtcSession && this.state.callMicrophoneIsMuted) {
      this.state.rtcSession.unmute({ audio: true, video: false })
      this.setState({ callMicrophoneIsMuted: false })
    }
  }

  private callToggleMuteMicrophone = () => {
    return this.state.callMicrophoneIsMuted ? this.callUnmuteMicrophone() : this.callMuteMicrophone()
  }

  private sendDTMF = (tones: string) => {
    if (this.state.rtcSession) {
      this.state.rtcSession.sendDTMF(tones)
    }
  }
}
