import React, { forwardRef, useRef } from 'react'
import { Controller, RegisterOptions, useController } from 'react-hook-form'
import ErrorMessages from './ErrorMessages'
import Tippy from '@tippyjs/react'
import Icon from '../Icon'

export type InputTextHandle = {
  focus: () => void
}

type InputTextProps = {
  name: string
  rules?: RegisterOptions
  label?: string | React.ReactElement
  placeholder?: string
  type?: string
  control?: any
  className?: string
  classNameInput?: string
  classNameLabel?: string
  helpText?: string
  warning?: string
  tooltip?: string
  editMode?: boolean
}

const parseBoolean = (value: any) => {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

const InputCheckbox = forwardRef<InputTextHandle, InputTextProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { name, type, label, rules = {}, control, placeholder } = props
  const editMode = props.editMode ?? true

  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name
  })
  const checked = parseBoolean(field.value)

  const labelClick = () => {
    if (inputRef.current) {
      inputRef.current.checked = !inputRef.current.checked
      field.onChange(inputRef.current.checked)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={() => (
        <div className={['input-wrap', 'input-wrap-checkbox', props.className ?? ''].join(' ')}>
          {editMode ? (
            <input
              className={props.classNameInput}
              ref={inputRef}
              placeholder={placeholder}
              type={'checkbox'}
              value="1"
              checked={checked}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          ) : (
            <span className="input-value-plain">
              {checked ? (
                <Icon code="tick" className="color-gray-94" />
              ) : (
                <Icon code="untick" className="color-gray-94" />
              )}
            </span>
          )}
          {!!label && (
            <>
              {props.tooltip && props.tooltip?.length > 0 ? (
                <Tippy content={props.tooltip} placement="top">
                  <label
                    htmlFor=""
                    className={['input-label', props.classNameLabel ?? ''].join(' ')}
                    onClick={labelClick}
                  >
                    {label}
                  </label>
                </Tippy>
              ) : (
                <label
                  htmlFor=""
                  className={['input-label', props.classNameLabel ?? ''].join(' ')}
                  onClick={labelClick}
                >
                  {label}
                </label>
              )}
            </>
          )}

          {fieldState.error && <ErrorMessages error={fieldState.error} className="mt-8" />}
          {props.helpText && props.helpText.length > 0 && (
            <span className={['input-help'].join(' ')}>{props.helpText}</span>
          )}
          {props.warning && props.warning.length > 0 && (
            <span className={['input-warning'].join(' ')}>{props.warning}</span>
          )}
        </div>
      )}
    />
  )
})

InputCheckbox.displayName = 'InputCheckbox'

export default InputCheckbox
