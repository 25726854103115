import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PotentialContactFilter } from './potentials.model'
import { RootState } from '../../store/main'
import { SelectOption } from '../../types'
import { PURGE } from 'redux-persist'

type PotentialsState = {
  country: SelectOption
  filters: PotentialContactFilter
}

export const potentialContactDefaultFilters: PotentialContactFilter = {
  website: '',
  field: '',
  keyword: '',
  isClosed: false
}

const potentialsInitialState: PotentialsState = {
  country: null,
  filters: potentialContactDefaultFilters
}

const potentialsSlice = createSlice({
  name: 'potentials',
  initialState: potentialsInitialState,
  reducers: {
    setCountry(state, action: PayloadAction<SelectOption>) {
      state.country = action.payload
    },
    clearCountry(state) {
      state.country = null
    },
    setFilters(state, action: PayloadAction<PotentialContactFilter>) {
      state.filters = action.payload
    },
    clearFilters(state) {
      state.filters = potentialContactDefaultFilters
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return potentialsInitialState
    })
  }
})
export default potentialsSlice.reducer
export const { setCountry, clearCountry, setFilters, clearFilters } = potentialsSlice.actions
export const potentialsSelector = (state: RootState) => state.potentials
