import '../styles/main.scss'

import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import { store } from '../store/main'
import { AuthGuard } from '../features/auth'
import { NextPage } from 'next'
import React, { useEffect, useState } from 'react'
import Iconset from '../components/Iconset'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorPage from '../components/Error/ErrorPage'
import * as Sentry from '@sentry/react'
import Alerts from '../features/alerts/Alerts'
import { useAlerts } from '../features/alerts/alerts.hook'
import Modals from '../components/Modal/Modals'

const queryClient = new QueryClient()

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean
}

function MyApp(props: AppProps) {
  const { Component, pageProps }: { Component: NextApplicationPage; pageProps: any } = props

  const [canRender, setCanRender] = useState(false)
  const [isOnline, setIsOnline] = useState(true)
  const alerts = useAlerts()

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true)
    }

    function offlineHandler() {
      setIsOnline(false)
      alerts.open({ text: 'Internet connection lost.', type: 'warning' })
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [])

  useEffect(() => {
    setCanRender(true)
  }, [canRender])

  if (!canRender) {
    return null
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
          {Component.requireAuth ? (
            <AuthGuard>
              <Component {...pageProps} />
            </AuthGuard>
          ) : (
            <Component {...pageProps} />
          )}
          <Alerts />
          <Modals />
          <Iconset />
        </Sentry.ErrorBoundary>
      </QueryClientProvider>
    </Provider>
  )
}

export default MyApp
