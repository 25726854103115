import * as PropTypes from 'prop-types'
import { RTCSession } from 'jssip/lib/RTCSession'

export interface ExtraHeaders {
  register?: string[]
  invite?: string[]
  hold?: string[]
}
export const extraHeadersPropType = PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))

// https://developer.mozilla.org/en-US/docs/Web/API/RTCIceServer
export type IceServers = Array<{
  urls: string | string[]
  username?: string
  credential?: string
  credentialType?: string
  password?: string
}>
export const iceServersPropType = PropTypes.arrayOf(PropTypes.object)

export interface Sip {
  status?: string
  errorType?: string
  errorMessage?: string

  host?: string
  port?: number
  user?: string
  password?: string
  autoRegister?: boolean
  autoAnswer: boolean
  sessionTimersExpires: number
  extraHeaders: ExtraHeaders
  iceServers: IceServers
  debug: boolean
}
export const sipPropType = PropTypes.shape({
  status: PropTypes.string,
  errorType: PropTypes.string,
  errorMessage: PropTypes.string,

  host: PropTypes.string,
  port: PropTypes.number,
  user: PropTypes.string,
  password: PropTypes.string,
  autoRegister: PropTypes.bool,
  autoAnswer: PropTypes.bool,
  sessionTimersExpires: PropTypes.number,
  extraHeaders: extraHeadersPropType,
  iceServers: iceServersPropType,
  debug: PropTypes.bool
})

export interface Call {
  id: string
  status: string
  direction: string
  counterpart: string
  microphoneIsMuted: boolean
  muteMicrophone: () => void
  unmuteMicrophone: () => void
  toggleMuteMicrophone: () => void
  isOnHold: boolean
  hold: () => void
  unhold: () => void
  toggleHold: () => void
  sendDTMF: (tone: string) => void
}
export const callPropType = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  direction: PropTypes.string,
  counterpart: PropTypes.string,
  microphoneIsMuted: PropTypes.bool,
  muteMicrophone: PropTypes.func,
  unmuteMicrophone: PropTypes.func,
  toggleMuteMicrophone: PropTypes.func,
  isOnHold: PropTypes.bool,
  hold: PropTypes.func,
  unhold: PropTypes.func,
  toggleHold: PropTypes.func,
  sendDTMF: PropTypes.func
})

export interface SipPhoneContext {
  sip: Sip
  call: Call

  registerSip: () => void
  unregisterSip: () => void

  answerCall: () => void
  startCall: (phone: string) => RTCSession
  stopCall: () => void
}
