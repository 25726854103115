import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { listenerMiddleware } from './listenerMiddleware'
import authReducer from '../features/auth/auth.slice'
import potentialsReducer from '../features/potentials/potentials.slice'
import workerStatsReducer from '../features/worker-stats/worker-stats.slice'
import callCampaignReducer from '../features/call-campaigns/call-campaigns.slice'

const authReducerPersisted = persistReducer({ key: 'auth', storage }, authReducer)
const potentialsReducerPersisted = persistReducer({ key: 'potentials', storage }, potentialsReducer)
const workerStatsReducerPersisted = persistReducer({ key: 'worker-stats', storage }, workerStatsReducer)
const callCampaignReducerPersisted = persistReducer({ key: 'call-campaigns', storage }, callCampaignReducer)

export const store = configureStore({
  reducer: {
    auth: authReducerPersisted,
    potentials: potentialsReducerPersisted,
    workerStats: workerStatsReducerPersisted,
    callCampaigns: callCampaignReducerPersisted
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      trunk: true
    }).prepend(listenerMiddleware.middleware)
  },
  devTools: process.env.NODE_ENV !== 'production'
})
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
