import Navbar from '../components/Navbar/Navbar'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useIdle } from '@uidotdev/usehooks'
import { useAuth } from '../features/auth'
import SipProvider from '../components/SipProvider'

type Props = {
  title: string
  color?: string
  children?: React.ReactNode
  hideNavbar?: boolean
}

export default function Layout({ title, color, hideNavbar, children }: Props) {
  const idle = useIdle(60 * 60 * 1000)
  const { logout, sipLogin, isAllowedIncomingCall, isAllowedCalling } = useAuth()

  useEffect(() => {
    if (idle) {
      logout()
    }
  }, [idle])

  const sipHost = sipLogin.server
  const sipHostPath = '/ws'
  const sipPort = 443
  const sipUsername = sipLogin.username
  const sipPassword = sipLogin.password

  const body = (
    <>
      {!hideNavbar && <Navbar title={title} />}
      <main>{children}</main>
    </>
  )

  return (
    <div className={clsx('layout', color ? `layout-color-${color}` : null)}>
      {isAllowedIncomingCall || isAllowedCalling ? (
        <SipProvider
          host={sipHost}
          port={sipPort}
          pathname={sipHostPath} // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
          user={sipUsername}
          password={sipPassword} // usually required (e.g. from ENV or props)
          autoRegister={true} // true by default, see jssip.UA option register
          autoAnswer={false} // automatically answer incoming calls; false by default
          iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
          sessionTimersExpires={120} // value for Session-Expires header; 120 by default
          debug={true}
        >
          {body}
        </SipProvider>
      ) : (
        body
      )}
    </div>
  )
}
