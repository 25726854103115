import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { LoadingLine } from './LoadingLine'

export function LoadingPage() {
  const router = useRouter()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleStart = (url: string) => {
      setLoading(true)
    }
    const handleComplete = (url: string) => {
      setLoading(false)
    }
    const handleError = (err: { cancelled: boolean }, url: string) => {
      setLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleError)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleError)
    }
  })

  return loading ? <LoadingLine /> : <></>
}
