import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Controller, RegisterOptions, useController } from 'react-hook-form'
import ErrorMessages from './ErrorMessages'
import Tippy from '@tippyjs/react'

export type InputTextHandle = {
  focus: () => void
}

type InputTextProps = {
  name: string
  rules?: RegisterOptions
  label?: string | React.ReactElement
  inputInsideIcon?: React.ReactElement
  placeholder?: string
  type?: string
  control?: any
  className?: string
  classNameInput?: string
  classNameLabel?: string
  helpText?: string
  warning?: string
  tooltip?: string
  editMode?: boolean
}

const InputText = forwardRef<InputTextHandle, InputTextProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { name, type, label, rules = {}, control, placeholder } = props
  const editMode = props.editMode ?? true

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    }
  }))

  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name
  })

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={() => (
        <div className={['input-wrap', props.className ?? ''].join(' ')}>
          {!!label && (
            <>
              {props.tooltip && props.tooltip?.length > 0 ? (
                <Tippy content={props.tooltip} placement="top">
                  <label htmlFor="" className={['input-label', props.classNameLabel ?? ''].join(' ')}>
                    {label}
                  </label>
                </Tippy>
              ) : (
                <label htmlFor="" className={['input-label', props.classNameLabel ?? ''].join(' ')}>
                  {label}
                </label>
              )}
            </>
          )}
          {editMode ? (
            <div className="input-input-wrap">
              <input
                className={props.classNameInput}
                ref={inputRef}
                placeholder={placeholder}
                type={type ?? 'text'}
                value={field.value === null || field.value === undefined ? '' : field.value}
                onInput={field.onChange}
                onBlur={field.onBlur}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              {props.inputInsideIcon && <div className="input-inside-icon">{props.inputInsideIcon}</div>}
            </div>
          ) : (
            <span className="input-value-plain">{field.value && field.value.length > 0 ? field.value : '--'}</span>
          )}

          {fieldState.error && <ErrorMessages error={fieldState.error} className="mt-8" />}
          {props.helpText && props.helpText.length > 0 && (
            <span className={['input-help'].join(' ')}>{props.helpText}</span>
          )}
          {props.warning && props.warning.length > 0 && (
            <span className={['input-warning'].join(' ')}>{props.warning}</span>
          )}
        </div>
      )}
    />
  )
})

InputText.displayName = 'InputText'

export default InputText
