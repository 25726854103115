const dummyLogger = {
  log: () => {
    /**/
  },
  error: () => {
    /**/
  },
  warn: () => {
    /**/
  },
  debug: () => {
    /**/
  }
}

export default dummyLogger
