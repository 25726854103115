import React, { ButtonHTMLAttributes } from 'react'

import TextLink from '../TextLink'
import Icon from '../Icon'

type Size = 'normal' | 'small' | 'big' | 'input'

type Props = {
  to?: any
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  width?: string
  size?: Size
  icon?: string
  iconSize?: string
  color?: string
  className?: string
  title?: string
  prefix?: string | JSX.Element
  suffix?: string | JSX.Element
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}
const Button = React.forwardRef(
  (
    { to, type, size, icon, color, className, prefix, suffix, onClick, disabled, loading, title, iconSize }: Props,
    ref
  ) => {
    const isLink = !!to
    const Component = isLink ? (TextLink as any) : 'button'
    const classNames = ['button', 'size-' + (size ? size : 'normal'), 'color-' + color, className]
    if (disabled) {
      classNames.push('disabled')
    }

    const bind: Record<string, any> = {
      onClick
    }

    if (isLink) {
      bind.to = to
    } else {
      bind.type = type ?? 'button'
    }

    return (
      <Component className={classNames.join(' ')} {...bind} ref={ref} disabled={disabled} title={title}>
        {loading ? (
          <Icon code="loading" size="16" className={!suffix ? 'mr-0' : ''} />
        ) : (
          <>
            <span className="prefix">{prefix}</span>
            {icon && <Icon code={icon} className={!suffix ? 'mr-0' : ''} size={iconSize} />}
            <span className="suffix">{suffix}</span>
          </>
        )}
      </Component>
    )
  }
)
Button.displayName = 'Button'

export default Button
