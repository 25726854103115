import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { authSelector, logoutApi } from './auth.slice'
import { DateTime } from 'luxon'
import { ISelectOption } from '../../components/Form/InputSelect'
import _ from 'lodash'
import DOMPurify from 'dompurify'
import { SelectOption } from '../../types'
import { saveSettings, setApiUserModel, setCountry } from '../auth'
import { useAppDispatch } from '../../hooks/main'
import { AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { ApiUserModel, UserSettings } from './auth.model'
import { persistor } from '../../store/main'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const auth = useSelector(authSelector)
  const [redirect, setRedirect] = useState('/')
  const getRedirect = () => redirect
  const isExpired = auth.tokenExpireTime ? DateTime.now() > DateTime.fromISO(auth.tokenExpireTime.toString()) : false
  const isAllowedIncomingCall = auth.account?.apiUserModel.isAllowedIncomingCall || false
  const isAllowedCalling = auth.account?.apiUserModel.isAllowedCalling || false

  const onSetCountry = (country: SelectOption) => {
    dispatch(setCountry(country))
    if (auth.account && country && country.value.length > 0) {
      saveUserSettings({
        selectedStateId: country.value
      } as UserSettings)
    }
  }

  const saveUserSettings = async (data: UserSettings) => {
    const response = await saveSettings(data)
    if (response?.data?.success && response?.data?.success === true && auth.account) {
      const newUserModel = {
        ...auth.account.apiUserModel
      } as ApiUserModel
      if (data.invoiceAddress) {
        newUserModel.invoiceAddress = data.invoiceAddress
      }
      if (data.invoiceCity) {
        newUserModel.invoiceCity = data.invoiceCity
      }
      if (data.invoiceZip) {
        newUserModel.invoicePsc = data.invoiceZip
      }
      if (data.personalEmail) {
        newUserModel.personalEmail = data.personalEmail
      }
      if (data.personalPhone) {
        newUserModel.personalPhone = data.personalPhone
      }
      if (data.bankAccount) {
        newUserModel.bankAccount = data.bankAccount
      }
      if (data.receiveCopySystemEmails) {
        newUserModel.receiveCopySystemEmails = data.receiveCopySystemEmails
      }
      if (data.callScreenTest) {
        newUserModel.callScreenTest = data.callScreenTest
      }
      if (data.callScreenTestPhone) {
        newUserModel.callScreenTestPhone = data.callScreenTestPhone
      }
      if (data.callScreenTestEmail) {
        newUserModel.callScreenTestEmail = data.callScreenTestEmail
      }
      updateApiUserModel(newUserModel)
    }
  }

  const stateOptions = useMemo(() => {
    return auth.account?.states.map(
      (state) =>
        ({
          value: state.id,
          label: state.stateCode
        } as ISelectOption)
    )
  }, [auth.account])

  const contactFieldOptions = useMemo(() => {
    return auth.account?.contactFields.map(
      (contactField) =>
        ({
          value: contactField.id,
          label: contactField.name
        } as ISelectOption)
    )
  }, [auth.account])

  const contactPersonTitleOptions = useMemo(() => {
    return (
      auth.account?.contactPersonTitles &&
      auth.account?.contactPersonTitles.reduce((finalPersonTitles, contactPersonTitle) => {
        if (
          auth.selectedCountry &&
          contactPersonTitle.stateId &&
          auth.selectedCountry.value == contactPersonTitle.stateId.toString()
        ) {
          finalPersonTitles.push({
            value: contactPersonTitle.id,
            label: contactPersonTitle.name
          } as ISelectOption)
        }
        return finalPersonTitles
      }, [] as ISelectOption[])
    )
  }, [auth.account, auth.selectedCountry])

  const contactPersonPositionOptions = useMemo(() => {
    return (
      auth.account?.contactPersonPositions &&
      auth.account?.contactPersonPositions.map(
        (contactField) =>
          ({
            value: contactField.id,
            label: contactField.name
          } as ISelectOption)
      )
    )
  }, [auth.account])

  const workerOptions = useMemo(() => {
    return auth.account?.workers.map(
      (worker) =>
        ({
          value: worker.id,
          label: worker.code + ' - ' + worker.fullName
        } as ISelectOption)
    )
  }, [auth.account])

  const workerCodeOptions = useMemo(() => {
    return auth.account?.workers.map(
      (worker) =>
        ({
          value: worker.code,
          label: worker.code + ' - ' + worker.fullName
        } as ISelectOption)
    )
  }, [auth.account])

  const hasPermission = (permission: string) => {
    return auth.account?.apiUserModel.permissions !== undefined
      ? auth.account?.apiUserModel.permissions.includes(permission)
      : false
  }
  const helperText = (code: string) => {
    const intranetHelpText = _.find(
      auth.account?.intranetHelpTexts,
      (intranetHelpText) => intranetHelpText.code === code
    )
    let html = ''
    if (intranetHelpText) {
      html = DOMPurify.sanitize(intranetHelpText.text, {
        USE_PROFILES: { html: true }
      })
    }
    return html
  }

  const checkApiResponse = (response: AxiosResponse) => {
    if (response?.status === 401) {
      logout()
    }
  }

  const logout = () => {
    dispatch(logoutApi()).then(() => {
      persistor.purge().then(() => {
        persistor.flush().then(() => {
          router.push('/login')
        })
      })
    })
  }

  const getContactPersonPositionById = (id: number | undefined | null) => {
    return _.find(auth.account?.contactPersonPositions, (contactPersonPosition) => contactPersonPosition.id === id)
  }

  const getContactPersonTitleById = (id: number | undefined | null) => {
    return _.find(auth.account?.contactPersonTitles, (contactPersonTitle) => contactPersonTitle.id === id)
  }

  const getContactFieldById = (id: number) => {
    return _.find(auth.account?.contactFields, (contactField) => contactField.id === id)
  }

  const getStateById = (id: number) => {
    return _.find(auth.account?.states, (state) => state.id === id)
  }

  const getSettingByProperty = (property: string) => {
    return _.find(auth.account?.settings, (setting) => setting.property === property)
  }

  const updateApiUserModel = (apiUserModel: ApiUserModel) => {
    dispatch(setApiUserModel(apiUserModel))
  }

  return useMemo(() => {
    return {
      ...auth,
      isExpired,
      getRedirect,
      setRedirect,
      stateOptions,
      states: auth.account?.states,
      stateCodes: auth.account?.states.map((state) => state.fmStateId),
      contactFieldOptions,
      workerOptions,
      workerCodeOptions,
      contactPersonTitleOptions,
      contactPersonPositionOptions,
      hasPermission,
      helperText,
      userAccount: auth.account?.apiUserModel,
      workersNoRemoteWithLine: auth.account?.workers.filter(
        (worker) => !worker.remoteWorker && worker.line && worker.line.length > 0
      ),
      setCountry: onSetCountry,
      selectedCountry: auth.selectedCountry,
      files: auth.account?.intranetFiles || [],
      checkApiResponse,
      sipLogin: {
        server: auth.account?.apiUserModel.sipServer,
        username: auth.account?.apiUserModel.sipUsername,
        password: auth.account?.apiUserModel.sipPassword
      },
      getContactPersonPositionById,
      getContactPersonTitleById,
      getContactFieldById,
      getStateById,
      updateApiUserModel,
      saveUserSettings,
      getSettingByProperty,
      logout,
      isAllowedIncomingCall,
      isAllowedCalling
    }
  }, [auth, redirect, setCountry])
}
