import React from 'react'
import { LoadingPage } from '../components/LoadingPage'
import clsx from 'clsx'

type Props = {
  submenu?: React.ReactNode
  children?: React.ReactNode
  className?: string
  mainClassName?: string
}

export default function Content({ children, submenu, className, mainClassName }: Props) {
  return (
    <div className={clsx('content', className)}>
      {submenu}
      <LoadingPage />
      <div className={clsx('content-main', mainClassName)}>
        <div className="content-main-inner">{children}</div>
      </div>
    </div>
  )
}
