import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAlertsStore } from '../../store/alerts'
import Alert from './Alert'

export default function Alerts() {
  const alertsStore = useAlertsStore()

  if (!alertsStore.list.length) {
    return <></>
  }

  return (
    <TransitionGroup className="alerts">
      {alertsStore.list.map((item) => (
        <CSSTransition
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          key={item.id}
          classNames="fade"
        >
          <Alert alert={item} onClose={alertsStore.remove} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}
