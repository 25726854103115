import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from '../store/main'
import { useState } from 'react'
import { Ref } from '../types'

export const ref = <T>(value: T): Ref<T> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<T>(value)

  return Object.defineProperty({} as Ref<T>, 'value', {
    get() {
      return state
    },
    set(value: T) {
      setState(value)
    }
  })
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
