import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/main'
import { PURGE } from 'redux-persist'

type CallCampaignsFilters = {
  workerTaskListPassedQue: number[]
}

export const workerStatsDefaultFilters: CallCampaignsFilters = {
  workerTaskListPassedQue: []
}

const callCampaignsSlice = createSlice({
  name: 'call-campaigns',
  initialState: workerStatsDefaultFilters,
  reducers: {
    setWorkerTaskListPassedQue(state, action: PayloadAction<number[]>) {
      state.workerTaskListPassedQue = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return workerStatsDefaultFilters
    })
  }
})
export default callCampaignsSlice.reducer
export const { setWorkerTaskListPassedQue } = callCampaignsSlice.actions
export const callCampaignsSelector = (state: RootState) => state.callCampaigns
