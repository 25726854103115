import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/main'
import { DateRange } from 'react-day-picker'
import { PURGE } from 'redux-persist'

type WorkerStatsFilters = {
  dateFrom: string
  dateTo: string
  worker: string | null
}

export const workerStatsDefaultFilters: WorkerStatsFilters = {
  dateFrom: new Date().toISOString(),
  dateTo: new Date().toISOString(),
  worker: null
}

const workerStatsSlice = createSlice({
  name: 'worker-stats',
  initialState: workerStatsDefaultFilters,
  reducers: {
    setDateFrom(state, action: PayloadAction<string>) {
      state.dateFrom = action.payload
    },
    setDateTo(state, action: PayloadAction<string>) {
      state.dateTo = action.payload
    },
    setDates(state, action: PayloadAction<DateRange>) {
      state.dateFrom = action.payload.from ? action.payload.from.toISOString() : ''
      state.dateTo = action.payload.to ? action.payload.to.toISOString() : ''
    },
    setWorker(state, action: PayloadAction<string>) {
      state.worker = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return workerStatsDefaultFilters
    })
  }
})
export default workerStatsSlice.reducer
export const { setDates, setDateFrom, setDateTo, setWorker } = workerStatsSlice.actions
export const workerStatsSelector = (state: RootState) => state.workerStats
