import { api } from '../../utils/api'
import { UserSettings } from './auth.model'

export function getUserToken(username: string, password: string) {
  return api.post(
    '/auth',
    {
      username,
      password
    },
    null,
    false
  )
}

export function logout() {
  return api.get('logout')
}

export async function getCommonData(token: string) {
  api.setToken(token)
  return api.get('common')
}

export function saveSettings(settings: UserSettings) {
  return api.post(`user/settings`, settings, {}, true)
}

export function getWorkerStatus() {
  return api.get(`worker-status`, {}, true)
}
