import AlertModel from './alert.model'
import clsx from 'clsx'
import Icon from '../../components/Icon'
import React from 'react'

type Props = {
  alert: AlertModel
  className?: string
  onClose: (alert: AlertModel) => void
}

export default function Alert({ alert, onClose, ...props }: Props) {
  return (
    <div className={clsx('alert', props.className, 'alert-type-' + alert.type)}>
      <div className="alert-details">
        <p className="text">{alert.text}</p>
      </div>
      <a className="alert-close" onClick={() => onClose(alert)}>
        <Icon code="cross" />
      </a>
    </div>
  )
}
