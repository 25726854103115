import { CSSTransition } from 'react-transition-group'
import { useModalsStore } from '../../store/modals'

export default function Modals() {
  const list = useModalsStore((state) => state.list)

  if (list.length === 0) {
    return <></>
  }

  return (
    <CSSTransition
      in={!!list.length}
      addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
      classNames="fade"
      unmountOnExit
    >
      <div className="modals">
        {list.map((modal) => {
          const Component = modal.component

          return <Component key={modal.id} modal={modal} {...modal.params} />
        })}

        <div className="modals-bg"></div>
      </div>
    </CSSTransition>
  )
}
