import { FieldError, Merge } from 'react-hook-form'

type Props = {
  error: FieldError | Merge<FieldError, any>
  className?: string
}

export default function ErrorMessages(props: Props) {
  let message = ''

  switch (props.error.type) {
    case 'required':
      message = 'Field is required.'
      break
    default:
      message = props.error.message ?? ''
      break
  }

  return <div className={['form-error', props.className ?? ''].join(' ')}>{message}</div>
}
