import Alert, { AlertOptions } from './alert.model'
import { useAlertsStore } from '../../store/alerts'
import { BasicFormResponse } from '../../types'
import { CallScreenActionResponseType } from '../call-campaigns/call-campaigns.model'

export const useAlerts = () => {
  const alertsStore = useAlertsStore()

  const open = (options: AlertOptions) => {
    const alert = new Alert(options)

    alertsStore.add(alert)

    alert.timer = setTimeout(() => {
      alert.timer = undefined
      close(alert)
    }, alert.timeout)

    return alert
  }

  const close = (alert: Alert | string) => {
    alertsStore.remove(alert)

    return true
  }

  const error = (error: Error) => {
    return open({
      type: 'error',
      text: error.message
    })
  }

  const showMessagesFromApi = (responseData: BasicFormResponse) => {
    if (responseData.success) {
      let message = 'Changes saved.'
      if (responseData.successMessages.length > 0) {
        message = responseData.successMessages.join('. ')
      }
      open({ text: message, type: 'success' })
    } else {
      if (responseData.successMessages.length > 0) {
        let message = responseData.successMessages.join('. ')
        open({ text: message, type: 'success' })
      }
    }
    if (responseData.errors) {
      let errorString = ''
      Object.keys(responseData.errors).forEach((key) => {
        errorString += key + ': ' + responseData.errors[key] + '\n'
      })
      open({ text: errorString, type: 'error' })
    }
  }

  const showAllMessagesFromApi = (responseData: CallScreenActionResponseType) => {
    let hasError = false
    if (responseData?.success && responseData?.success.length > 0) {
      const successMessage = responseData?.success.reduce((acc, item) => {
        return acc + item.message + '. '
      }, '')
      open({ text: successMessage, type: 'success' })
    }
    if (responseData?.errors && responseData?.errors.length > 0) {
      const errorMessage = responseData?.errors.reduce((acc, item) => {
        return acc + item.message + '. '
      }, '')
      open({ text: errorMessage, type: 'error' })
      hasError = true
    }
    if (responseData?.warnings && responseData?.warnings.length > 0) {
      const warningMessage = responseData?.warnings.reduce((acc, item) => {
        return acc + item.message + '. '
      }, '')
      open({ text: warningMessage, type: 'warning' })
    }
    return hasError
  }

  return {
    open,
    close,
    error,
    showMessagesFromApi,
    showAllMessagesFromApi
  }
}
