import React from 'react'
import clsx from 'clsx'

type Props = {
  code: string
  title?: string
  className?: string
  size?: string
  style?: React.CSSProperties
  counter?: number
  counterPosition?: 'bottom-right' | 'top-right' | 'bottom-left' | 'top-left'
}

export default function Icon({ code, title, className, size, style, counter, counterPosition }: Props) {
  return (
    <span className={['icon', 'icon-size-' + (size ? size : '16'), className].join(' ')} title={title} style={style}>
      {code === 'loading' ? (
        <span className={['loader-block', 'size-' + (size ? size : '16')].join(' ')} />
      ) : (
        <svg className={`icon-${code}`}>
          <use xlinkHref={`#icon-${code}`}></use>
        </svg>
      )}
      {counter && <span className={clsx('counter', 'color-white', 'bg-red', counterPosition)}>{counter}</span>}
    </span>
  )
}
